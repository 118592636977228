export interface TextTemplate {
  id: string;
  name: string;
  messageType: MessageTypeEnum;
  subject?: string;
  content: string;
  attachments?: Record<string, boolean>;
}

export enum MessageTypeEnum {
  custom = 'custom',
  late_attendants = 'late-attendants',
  invitation = 'invitation',
  refused = 'refused',
  gift = 'gift',
}

export const MessageTypeMapping: Record<MessageTypeEnum, string> = {
  [MessageTypeEnum.custom]: 'Personnalisé',
  [MessageTypeEnum.late_attendants]: 'Retardataire',
  [MessageTypeEnum.invitation]: 'Invitation',
  [MessageTypeEnum.refused]: 'Refus',
  [MessageTypeEnum.gift]: 'Cadeau',
};
